import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Translation } from 'react-i18next'
import { Link as RebassLink } from 'rebass/styled-components'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import WorkshopSet from '../components/WorkshopSet'
import { draftjsContentToHTML } from '../utils/draftjsContentToHTML'
import { sortWorkshopSets } from '../utils/sortWorkshopSets'
import { withI18next } from 'gatsby-plugin-i18n'
import isBrowser from '../utils/isBrowser'

const WorkshopTemplate = ({ data, pageContext }) => {
  const { workshop, translations } = data
  const { language } = pageContext
  const {
    title,
    slug: workshopSlug,
    content,
    workshopSets,
    presentationFile,
  } = workshop
  const contentHTML = draftjsContentToHTML(content)
  const [logged] = useState(
    isBrowser ? localStorage.getItem('isLoggedIn') : false,
  )

  const sortedWorkshopSets = sortWorkshopSets(workshopSets)

  return (
    <Translation>
      {(t) => (
        <Layout language={language} translations={translations} translationsRoute="workshop">
          <main>
            <LightSection>
              <Container maxWidth="80rem">
                <SectionTitle as="h1">{title}</SectionTitle>

                <div dangerouslySetInnerHTML={{ __html: contentHTML }} />

                {presentationFile && logged && (
                  <RebassLink
                    variant="buttonLink"
                    href={presentationFile.url}
                    download={presentationFile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      mb: 4,
                    }}
                  >
                    {t('workshop:downloadButtonLabel', {
                      defaultValue: 'Download presentation',
                    })}
                  </RebassLink>
                )}

                {sortedWorkshopSets &&
                  sortedWorkshopSets.map((set) => {
                    return (
                      <WorkshopSet
                        key={set.id}
                        set={set}
                        workshopSlug={workshopSlug}
                        language={language}
                      />
                    )
                  })}
              </Container>
            </LightSection>
          </main>
        </Layout>
      )}
    </Translation>
  )
}

WorkshopTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withI18next()(WorkshopTemplate)

export const pageQuery = graphql`
  query WorkshopTemplate($id: String!, $language: String!, $translatedIds: [String]) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "messages" } }
    ) {
      ...LocalLocaleFragment
    }
    translations: allWorkshops(filter: { _id: { in: $translatedIds } }) {
      edges {
        node {
          id
          title
          slug
          language
        }
      }
    }
    workshop: workshops(id: { eq: $id }) {
      id
      title
      slug
      content
      presentationFile {
        url
      }
      image {
        local {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      workshopSets {
        id
        title
        slug
        presentations {
          id
          title
          slug
          versions {
            position
            title
            slug
          }
        }
        image {
          local {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
