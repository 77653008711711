import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import {
  Box,
  Flex,
  Card,
  Heading,
  Link as RebassLink,
} from 'rebass/styled-components'

import { Link } from 'gatsby-plugin-i18n'

const WorkshopSet = ({ set }) => {
  const { id, title, image, presentations } = set

  return (
    <Card
      key={id}
      sx={{
        p: 0,
        mb: 4,
        bg: 'white',
        boxShadow: 'card',
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'column'],
        }}
      >
        <Box
          sx={{
            width: ['100%', '100%', '100%'],
            order: [0, 0, 0],
          }}
        >
          {image && image.local && (
            <Img fluid={image.local.childImageSharp.fluid} alt={title} />
          )}
        </Box>

        <Box
          sx={{
            width: ['100%', '100%', '100%'],
            order: [1, 1, 1],
            p: 4,
          }}
        >
          <Heading as="h2" sx={{ mb: 3 }}>
            {set.title}
          </Heading>

          {presentations && (
            <Box sx={{ m: 0, p: 0, listStyle: 'none' }} as="ul">
              {presentations.map((presentation) => {
                let link = `/presentation/${presentation.slug}`
                const { versions } = presentation

                if (versions) {
                  const firstVersion = versions.filter(
                    (version) =>
                      version.position === 0 || version.position === null,
                  )[0]
                  const firstVersionSlug =
                    typeof firstVersion !== 'undefined'
                      ? firstVersion.slug
                      : false

                  if (firstVersionSlug) {
                    link = `/presentation/${presentation.slug}/${firstVersion.slug}`
                  }
                }

                return (
                  <li key={presentation.id}>
                    <RebassLink
                      as={Link}
                      to={link}
                      sx={{
                        color: 'primary',
                        fontSize: 3,
                        textDecoration: 'none',
                        '&:visited': {
                          color: 'primary',
                        },
                        '&:hover, &:focus': {
                          textDecoration: 'underline',
                        },
                      }}
                      state={{ workshopSetId: id }}
                    >
                      {presentation.title}
                    </RebassLink>
                  </li>
                )
              })}
            </Box>
          )}
        </Box>
      </Flex>
    </Card>
  )
}

WorkshopSet.propTypes = {
  set: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.object,
    presentations: PropTypes.array,
  }),
  workshopSlug: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
}

export default WorkshopSet
