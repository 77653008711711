const compare = (a, b) => {
  if (
    Object.prototype.hasOwnProperty.call(a, 'presentations') &&
    Object.prototype.hasOwnProperty.call(b, 'presentations') &&
    a.presentations &&
    b.presentations
  ) {
    if (a.presentations.length > b.presentations.length) return 1
    if (a.presentations.length === b.presentations.length) return 0
    if (a.presentations.length < b.presentations.length) return -1
  }

  return 0
}

export const sortWorkshopSets = (sets) => {
  if (Array.isArray(sets)) {
    return sets.sort(compare)
  }

  return sets
}
